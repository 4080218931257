/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Link from 'next/link';

const NotFoundPage = () => {
  return (
    <div>
      <section
        className="error-section ptb-120 bg-dark min-vh-100 w-100 d-flex flex-column justify-content-center"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-6">
              <div className="error-page-content-wrap">
                <h2 className="error-404 text-warning">404</h2>
                <h1 className="display-5 fw-bold">Seite nicht gefunden</h1>
                <p className="lead">
                  Irgendwas ist schief gelaufen.{' '}
                </p>

                <Link href="/">
                  <a className="btn btn-primary mt-4">Startseite</a>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 mt-5 d-none d-md-block d-lg-block">
              <div className="hero-img position-relative circle-shape-images">

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotFoundPage;
